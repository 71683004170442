import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
  },
  getters: {
    getLoading(state){
      return state.isLoading
    }
  },
  mutations: {
    setLoading(state, value){
      state.isLoading = value
    }
  },
  actions: {
  },
  modules: {
  }
})
