<template>
    <v-app class="h-screen">
      <LoaderCustom v-if="$store.getters.getLoading"/>
      <v-main class="relative h-full">
        <router-view/>
      </v-main>
    </v-app>

</template>

<script>
import LoaderCustom from '@/views/Loader.vue'

export default {
  name: 'App',

  components: {
    LoaderCustom,
  },

  data: () => ({
  
  }),
  mounted(){
    
  }
};
</script>
