<template>
  <div>
    <div class="pl-10 pt-8 flex flex-col bg-white h-24">
      <div class="flex flex-col pd-10 mx-80">
        <span class="text-2xl text-2sm antialiased text-justify text-[#104050]"
          >Alphachain Explorer</span
        >
      </div>
    </div>
    <div
      style="background-color: #104050; height: 170px"
      class="p-6 pl-10 flex flex-col"
    >
      <div class="flex flex-col mx-80">
        <form @submit.prevent="getdata">
          <label
            for="default-search"
            class="
              mb-2
              text-sm
              font-medium
              text-gray-900
              sr-only
              dark:text-gray-300
            "
            >Search</label
          >
          <div class="relative">
            <div
              class="
                flex
                absolute
                inset-y-0
                left-0
                items-center
                pl-3
                pointer-events-none
              "
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              v-model="query"
              class="
                block
                p-4
                pl-10
                w-full
                text-sm text-gray-900
                bg-gray-50
                rounded-lg
                border border-gray-300
                focus:ring-blue-500 focus:border-blue-500
                dark:bg-gray-700
                dark:border-gray-600
                dark:placeholder-gray-400
                dark:text-white
                dark:focus:ring-blue-500
                dark:focus:border-blue-500
              "
              placeholder="Tx hash, Address or Blocks"
              required
            />
            <button
              type="submit"
              class="
                text-white
                absolute
                right-2.5
                bottom-2.5
                bg-[#104050]
                hover:bg-[#FFA140]
                focus:ring-4 focus:outline-none focus:ring-blue-300
                font-medium
                rounded-lg
                text-sm
                px-4
                py-2
                dark:bg-[#104050]
                dark:hover:bg-blue-700
                dark:focus:ring-blue-800
              "
            >
              Search
            </button>
          </div>
        </form>
      </div>
      <div class="flex flex-col-2">
        <div class="flex flex-row mx-80">
          <span
            class="text-2xl text-2sm antialiased text-justify py-8 text-white"
          >
            Block Details
          </span>
        </div>

        <div class="flex flex-row ml-96 py-8">
          <button
            @click="redirectToHome"
            class="
              antialiased
              text-justify
              py-2
              text-white
              px-2
              bg-[#104050]
              hover:bg-[#FFA140]
              rounded-lg
              drop-shadow-md
            "
          >
            Home
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button
            @click="routeTo('https://faucet.all.co.tz/')"
            class="
              antialiased
              text-justify
              py-2
              text-white
              px-2
              bg-[#104050]
              hover:bg-[#FFA140]
              rounded-lg
              drop-shadow-md
            "
          >
            Faucet
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button
            @click="routeTo('https://alphachain.all.co.tz/dapps')"
            class="
              antialiased
              text-justify
              py-2
              text-white
              px-2
              bg-[#104050]
              hover:bg-[#FFA140]
              rounded-lg
              drop-shadow-md
            "
          >
            dApps
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button
            @click="routeTo('https://alphachain.all.co.tz/Wallet')"
            class="
              antialiased
              text-justify
              py-2
              text-white
              px-2
              bg-[#104050]
              hover:bg-[#FFA140]
              rounded-lg
              drop-shadow-md
            "
          >
            Wallet
          </button>
        </div>
      </div>
    </div>
    <div class="bg-[#E2E8F0] flex flex-col" style="height: 1000px">
      <div class="bg-white rounded-lg mt-6 mx-80" style="height: 950px">
        <div class="flex flex-col">
          <div class="rounded-sm m-10 h-full">
            <div class="flex flex-row rounded-lg" style="height: 770px">
              <div class="flex flex-row w-1/4">
                <div class="flex flex-col">
                  <span class="text-[#104050] font-bold p-8">
                    Block Height:
                  </span>

                  <span class="text-[#104050] font-bold p-8"> Status: </span>
                  <span class="text-[#104050] font-bold p-8"> Timestamp: </span>
                  <span class="text-[#104050] font-bold p-8">
                    Transactions:
                  </span>
                  <span class="text-[#104050] font-bold p-8"> Miner: </span>
                  <span class="text-[#104050] font-bold p-8">
                    Total Difficulty:
                  </span>
                  <span class="text-[#104050] font-bold p-8"> Gas Limit: </span>
                </div>
              </div>
              <div class="flex flex-row w-3/4">
                <div class="flex flex-col p-8">
                  <span class="text-[#104050] font-bold">
                    {{ foundBlockAddress }}
                  </span>

                  <span class="text-[#104050] font-bold py-16">
                    Confrimation
                  </span>
                  <span class="text-[#104050] font-bold flex flex-row">
                    <Icon icon="fluent-mdl2:buffer-time-after" class="mr-2"/>
					{{time1}}
                    <!-- 59 mins ago (Oct-17-2022 12:12:23 PM +UTC) -->
                  </span>
                  <span class="text-[#104050] font-bold py-16">
                    {{tx2}} 
                  </span>
                  <span class="text-[#104050] font-bold">
                    {{mine2}}
                  </span>
                  <span class="text-[#104050] font-bold py-16"> {{difficult3}} </span>
                  <span class="text-[#104050] font-bold"> {{limit}} </span>
                </div>
              </div>
            </div>
            <div></div>
          </div>

          <!-- <div class="flex pl-8 rounded-lg justify-between">
             <div class="flex justify-start">
               
               <button class="antialiased text-justify px-5 py-2 text-white bg-[#104050] hover:bg-[#FFA140] rounded-lg drop-shadow-md">
                 Back
               </button>
                </div>
                
               <div class="flex justify-end pr-8">
                 
               <button class="antialiased text-justify px-5 py-2 text-white bg-[#104050] hover:bg-[#FFA140] rounded-lg drop-shadow-md">
                 Next
               </button>
             </div>  
             </div> -->
        </div>
      </div>
    </div>
    <div>
      <FooterView />
    </div>
  </div>
</template>

<script>
import FooterView from "../components/FooterView.vue";
import { VueGoodTable } from "vue-good-table";
import { ethers } from "ethers";
import { Icon } from "@iconify/vue2";

export default {
  name: "Blockdetails",

  components: {
    FooterView,
    VueGoodTable,
    Icon,
  },
  data() {
    return {
      	currentBlocks: [],
      	transactionBlocks: [],
      	foundBlockAddress: "",
      	query: "",
	  	miner : null,
		mine2 : null,
		tx2 : null,
		time1 : null,
		limit : null,
		cBlock : null,
		difficulty : null,
		difficult2 : null,
		difficult3 : null,
    };
  },
  methods: {
    //home
    redirectToHome() {
      this.$router.push("/");
    },
    //Blank
    routeTo(dest) {
      window.open(dest, "_blank");
    },
    async getdata() {
      // let provider = await new ethers.providers.JsonRpcProvider({url: "https://rpc.all.tz",});
      let x = 42;
      let y = 66;
      let paramLength = this.query.length;

      if (paramLength == x) {
        // Account Address hash
        this.$router.replace({
          name: "Accountdetails",
          params: { accountAddress: this.query },
        });
		if (this.$router.name = "Accountdetails") window.location.reload()
      } else if (paramLength == y) {
        // Transaction Hash
		this.$router.replace({
			name: "Transactiondetails",
			params: { transactionAddress: this.query },
		});
		if (this.$router.name = "Transactiondetails") window.location.reload()
      } else {
			this.$router.replace({
				name: "Blockdetails",
				params: { blockAddress: this.query },
			});
			if (this.$router.name = "Blockdetails") window.location.reload()
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    blocks(newBlocks, oldBlocks) {
      return newBlocks;
    },
    time(newTime, oldTime) {
      return newTime - oldTime;
    },
  },
  beforeMount() {
	this.$store.commit("setLoading", true)
  },
  async mounted() {
    // let first = document.querySelectorAll(".vgt-table tr")[0];
    // let second = document.querySelectorAll(".vgt-table tr")[2];
    // first.style.display = "none";
    // second.style.display = "none";

    this.foundBlockAddress = this.$route.params.blockAddress;
    console.log(parseInt(this.foundBlockAddress));

    let provider = new ethers.providers.JsonRpcProvider({
      url: "https://rpc.all.tz",
    });
    await provider.getBlock(parseInt(this.foundBlockAddress))

		.then((miner) => {
			this.miner = miner
			this.mine2 = this.miner.miner.toLowerCase();
			this.tx2 = this.miner.transactions.length + " transactions in the block";
			this.time1 = new Date(this.miner.timestamp).toLocaleTimeString("en-UK");
			this.limit = this.miner.gasLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " m/s",
			this.cBlock = this.miner._difficulty,
		
			this.difficulty = (this.cBlock / 1000000).toFixed(6).toString().split("");
			this.difficulty.pop();
			this.difficulty.pop();
			this.difficulty.pop();
	
			this.difficult2 = "";
			this.difficulty.forEach((e) => {
			this.difficult2 += e;
			});
	
			this.difficult3 = this.difficult2 + "M";
		})
		.finally(() => {
			this.$store.commit("setLoading", false)
		})

    console.log("block",this.miner);
	  console.log("time", this.miner?.timestamp);
	  console.log("this is ",this.cBlock);
  },
};
</script>