<template>
     <div>
      <div class="pl-10 pt-8 flex flex-col bg-white h-24">
          <div class="flex flex-col pd-10 mx-80">
            <span class="text-2xl text-2sm  antialiased text-justify text-[#104050]">Alphachain Explorer</span>
          </div>
        </div>
        <div style="background-color: #104050; height: 170px" class="p-6 pl-10 flex flex-col">
            <div class="flex flex-col mx-80 ">
              <form @submit.prevent="getdata">   
                  <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search</label>
                  <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                          <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                      </div>
                      <input type="search" v-model="query" class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tx hash, Address or Blocks" required>
                      <button  type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-[#104050] hover:bg-[#FFA140] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-[#104050] dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                  </div>
              </form>
            </div>

            <div class="flex flex-col-2">
              
              <div class="flex flex-row mx-80">
                <span class="text-2xl text-2sm  antialiased text-justify py-8 text-white">
                Transactions
              </span>
              </div>
              
              <div class="flex flex-row ml-96 py-8">
               <button @click="redirectToHome" class="antialiased text-justify py-2 text-white  px-2 bg-[#104050] hover:bg-[#FFA140] rounded-lg drop-shadow-md">
                 Home
               </button>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button  @click="routeTo('https://faucet.all.co.tz/')"  class="antialiased text-justify py-2 text-white  px-2 bg-[#104050] hover:bg-[#FFA140] rounded-lg drop-shadow-md">
                 Faucet
               </button>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <button  @click="routeTo('https://alphachain.all.co.tz/dapps')"   class="antialiased text-justify py-2 text-white px-2 bg-[#104050] hover:bg-[#FFA140] rounded-lg drop-shadow-md">
                 dApps
               </button>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <button  @click="routeTo('https://alphachain.all.co.tz/Wallet')" class="antialiased text-justify py-2 text-white  px-2 bg-[#104050] hover:bg-[#FFA140] rounded-lg drop-shadow-md">
                 Wallet
               </button> 
              </div>
            </div>
          </div>
        <div class="bg-[#E2E8F0] flex flex-col" style="height:1000px">
          <div class="bg-white rounded-lg mt-6 mx-80" style="height:950px">
            <div class="flex flex-col">
              <div class="rounded-sm m-10">
                <v-data-table :headers="headers" :items="rows" :items-per-page="15">
                  <template v-slot:[`item.txhash`]="{item}">
                    {{item.txhash | truncate}}
                  </template>
                  <template v-slot:[`item.to`]="{item}">
                    {{item.to | truncate}}
                  </template>
                  <template v-slot:[`item.from`]="{item}">
                    {{item.from | truncate}}
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>

        </div>
      <div>
      <FooterView/>
      </div>

    </div>
</template>

<script>
import FooterView from "../components/FooterView.vue";
import { VueGoodTable } from "vue-good-table";
import { ethers } from "ethers";
import { pick } from 'lodash';

export default {
  name: "Tx",
  components: {
    FooterView,
    VueGoodTable,
  },
  data() {
    return {
      query: null,
      data: [],
      headers: [
        {
          text: "Txn Hash",
          value: "txhash",
          class: "text-truncate"
        },
        {
          text: "Time",
          value: "time",
          sortable: false
        },
        {
          text: "Block",
          value: "block",
        },
        {
          text: "From",
          value: "from",
        },
        {
          text: "To",
          value: "to",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Gas price",
          value: "gasprice",
        },
      ],
      rows: [
        
      ],
      currentBlocks: [],
      transactionBlocks: [],
    };
  },
  methods: {
    onRowClick(){
      this.$router.push('')
    },
    //home
    redirectToHome() {
      this.$router.push('/')
    },
    //Blank
    routeTo(dest) {
      window.open(dest,'_blank');
    },
    //Search function
    async getdata(){
      let provider = await new ethers.providers.JsonRpcProvider({url: "https://rpc.all.tz",}); 
          let x = 42
          let y = 66 
          let paramLength = this.query.length
      
      if (paramLength == x) { // Account Address hash
        this.$router.push({
          name: "Accountdetails",
          params: {accountAddress: this.query}
        })
      } 
      else if ( paramLength == y) { // Transaction Hash
        this.$router.push({
          name: "Transactiondetails",
          params: {transactionAddress: this.query}
        })
      } 
      else {
        this.$router.push({
          name: "Blockdetails",
          params: {blockAddress: this.query}
        })
      } 
    },
    
  },
  watch: {
    // whenever question changes, this function will run
    blocks(newBlocks, oldBlocks) {
      return newBlocks;
    },
    time(newTime, oldTime) {
      return newTime - oldTime;
    },
  },
  async mounted() {
    // let th = document.querySelectorAll('th')
    //   th.forEach(h => h.classList.remove("sortable"))

    setInterval(async () => {
      let provider = new ethers.providers.JsonRpcProvider({
        url: "https://rpc.all.tz",
      });
      this.miner = await provider.getBlock();
      this.tx = await provider.getBlockWithTransactions();
      // this.amount =  ethers.utils.formatEther(this.tx?.value?._hex);
      // console.log("tx",pick(this.tx, 'transactions')['transactions'])
      // console.log("example",pick(this.tx, 'transactions')['transactions'][0]['blockNumber'])

      let transactions = pick(this.tx, 'transactions')['transactions']
      // console.log("this transactions",transactions)

      transactions.forEach((tx) => {
        this.rows.unshift({
          "txhash" : tx?.hash,
          "time" : new Date(this.miner.timestamp).toLocaleTimeString([],{second: "2-digit",}) + "sec ago",
          "block" : tx?.blockNumber,
          "from" : tx?.from,
          "to" : tx?.to,
          "amount": ethers.utils.formatEther(tx?.value),
          "gasprice": this.gasprice,
        })
        this.rows = [...new Set(this.rows)]
      })
      
      this.blocks = await provider.getBlockNumber();
      this.gasprice = await provider.getGasPrice();
      this.gasprice = ethers.utils.formatEther(this.gasprice);
      // console.log("this is it my boy",this.gasprice)
      let time = new Date(this.miner.timestamp).toLocaleTimeString([], {
        second: "2-digit",
      }) + "sec ago";
      // console.log("this time",new Date(this.miner.timestamp).toLocaleTimeString([],{second: "2-digit",}) + "sec ago")
      
     
      //CURRENT BLOCKS LOADING AND FORMATTING
      this.currentBlock = {};
      this.currentBlock.block = this.tx.number;
      this.currentBlock.miner = this.miner.miner.toLowerCase();
      this.currentBlock.txcount = this.tx.transactions.length;
      this.currentBlock.gasprice =  this.gasprice;
      this.currentBlock.txhash = this.tx.transactions[0]?.hash;
      this.currentBlock.to = this.tx.transactions[0]?.to;
      this.currentBlock.from = this.tx.transactions.from;
      this.currentBlock.time = time;
      this.currentBlock.gaslimit = this.tx.gasLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +" m/s"

      // this.rows.push(this.currentBlock);
      ;
    }, 5000);
  },
};
</script>
<style>
  ::-webkit-scrollbar-track{
    height: 4px;
    width: 4px;
    background: rgb(248, 248, 237);
  }
  ::-webkit-scrollbar{
    width: 6px;
    height: 6px;
    cursor: pointer;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb{
    width: 4px;
    height: 4px;
    border-radius: 4px;
    cursor: pointer !important;
    background: rgb(16, 64,80);
  }
  span {
  @apply text-[#104050] antialiased font-medium;
}
</style>