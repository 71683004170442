import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Comingsoon from '../views/Comingsoon'
import Block from '../views/Block'
import Tx from '../views/Tx'
import Blockdetails from '../views/Blockdetails'
import Accountdetails from '../views/Accountdetails'
import Transactiondetails from '../views/Transactiondetails'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: true
  },
  {
    path: '/coming_soon',
    name: 'Comingsoon',
    component:Comingsoon,

  },
  {
    path: '/block',
    name: 'Block',
    component:Block,
    props: true
  },
  {
    path: '/Tx',
    name: 'Tx',
    component:Tx,
    props: true
  },
  {
    path: '/Blockdetails/:blockAddress',
    name: 'Blockdetails',
    component:Blockdetails,
    props:true
  },
  {
    path: '/Accountdetails/:accountAddress',
    name: 'Accountdetails',
    component:Accountdetails,
    props:true
  },
  {
    path:'/Transactiondetails/:transactionAddress',
    name:'Transactiondetails',
    component:Transactiondetails,
    props:true
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
