<template>
  <div>
    <div class="">
      <div>
        <div
          style="background-color: #ffffff; height: 10px"
          class="pl-10 pt-8 pb-16 flex flex-col-2"
        >
          <div class="flex flex-col pd-10 mx-80">
            <span
              class="
                text-2xl text-2sm
                antialiased
                text-justify
                
                text-[#104050]
              "
              >Alphachain Explorer</span
            >
          </div>


          <div class="flex flex-col pb-12 mx-96">
            <span class="text-xs">
              Powered by
            </span>
            <span class="">
              <img src="../assets/logo.png" class="object-scale-down h-24 w-48 pb-12"> 
              </span>
          </div>
        </div>
        <div style="height: 250px" class="relative bg-[#104050]">

          <div style="background-color: #104050; height: 200px" class="p-6 pl-10 flex flex-col">
            <div class="flex flex-col mx-80">
              <form @submit.prevent="getdata">   
                  <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search</label>
                  <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                          <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                      </div>
                      <input type="search" v-model="query" class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tx hash, Address or Blocks" required>
                      <button  type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-[#104050] hover:bg-[#FFA140] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-[#104050] dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                  </div>
            </form>
            </div>
          </div>

          <div style="height: 100px" class="flex flex-col mx-80">
            <div class="p-2 h-full bg-white rounded-md">
              <div
                class="
                  flex flex-cols-3
                  justify-evenly
                  bg-white
                  rounded
                  h-full
                  w-full
                "
              >
                <div class="pt-2 flex flex-col" id="customtext2">
                  <div class="flex flex-col pt-2">
                    <span
                      class="
                        text-2xl
                        subpixel-antialiased
                        font-bold font-sans
                        text-[#104050]
                        block
                      "
                    >
                      {{ blocks }}
                    </span>

                    <div class="flex flex-cols-2">
                      <div class="pt-1">
                        <span
                          class="
                            subpixel-antialiased
                            font-bold font-sans
                            text-[#104050]
                          "
                        >
                          <Icon icon="clarity:blocks-group-solid" />
                        </span>
                      </div>

                      <div class="pb-4">
                        <span
                          class="subpixel-antialiased font-sans text-[#104050]"
                        >
                          Blocks
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pt-2" id="customtext2">
                  <div class="pt-2">
                    <span
                      class="
                        text-2xl
                        subpixel-antialiased
                        font-bold font-sans
                        text-[#104050]
                      "
                      id="currentDiffSpan"
                    >
                    </span>

                    <div class="flex flex-cols-2"  >
                      <div class="pt-1">
                        <span
                          class="
                            subpixel-antialiased
                            font-bold font-sans
                            text-[#104050]
                          "
                        >
                          <Icon icon="clarity:blocks-group-solid" />
                        </span>
                      </div>

                      <div class="pb-4">
                        <span
                          class="
                            subpixel-antialiased
                            font-sans
                            text-[#104050]
                            py-12
                          "
                          >Difficult</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pt-2" id="customtext2">
                  <div class="pt-2">
                    <span
                      class="
                        text-2xl
                        subpixel-antialiased
                        font-bold font-sans
                        text-[#104050]
                      "
                      id="gasprice"
                    >
                    </span>
                  </div>
                  <div class="flex flex-cols-2" >
                    <div class="pt-2">
                      <span
                        class="
                          subpixel-antialiased
                          font-bold font-sans
                          text-[#104050]
                        "
                      >
                        <Icon icon="bxs:gas-pump" />
                      </span>
                    </div>

                    <div class="flex flex-1 pt-1">
                      <span
                        class="subpixel-antialiased font-sans text-[#104050]"
                        >Gas</span
                      >
                      <span
                        class="subpixel-antialiased font-sans text-[#104050]"
                      >
                        price
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="bg-slate-200"></div>

      <div class="bg-slate-200">
        <div class="py-10"></div>
        <div
          style="height: 500px"
          class="mx-80 bg-white rounded-md flex flex-col"
        >
          <div class="pt-8 pl-14 mr-96">
            <div class="flex flex-row">
              <div class="">
                <span
                  class="text-2xl subpixel-antialiased font-sans text-[#104050]"
                >
                  <Icon icon="bi:hash" />
                </span>
              </div>

              <div class="">
                <span class="text-2xl antialiased text-justify text-[#104050]"
                  >Number of Transactions</span
                >
              </div>
            </div>
          </div>

          <div class="mx-20 pt-6 rounded-md">
            <span class="antialiased text-justify text-[#104050]">
              <div class="rounded-lg overflow-hidden flex flex-col">
                <div id="chart">
                  <apexchart
                    ref="barchart"
                    type="bar"
                    width="100%"
                    height="350"
                    background="#FFF"
                    :options="chartOptions"
                    :series="series"
                  >
                  </apexchart>
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="py-10"></div>

        <div class="mx-5 bg-slate-200">
          <div class="grid grid-cols-2 mx-72">
            <div>
              <div class="p-6 grow mr-2 bg-white rounded-md">
                <div class="flex flex-col">
                  <div class="flex flex-row">
                    <div class="bg-white">
                      <span
                        class="text-2xl antialiased text-justify text-[#104050]"
                      >
                        <Icon icon="clarity:blocks-group-solid" />
                      </span>
                    </div>

                    &nbsp; &nbsp;

                    <div>
                      <span
                        class="text-2xl antialiased text-justify text-[#104050]"
                      >
                        Recent Blocks
                      </span>
                    </div>
                  </div>
                  <hr class="my-2" />
                </div>

                <div class="flex flex-row h-screen" style="height: 500px">
                  <div class="rounded-md w-full">
                    <div
                      class="w-full h-full flex max-h-screen flex-col flex-grow"
                    >
                      <div
                        class="
                          container
                          rounded
                          flex flex-col
                          w-full
                          h-25
                          scrollbar
                        "
                      >
                        <vue-good-table
                          :columns="columns"
                          :rows="currentBlocks"
                          :sort-options="{
                            enabled: false,
                            multipleColumns: false,
                            initialSortBy: [{ field: 'blocks', type: 'desc' }],
                          }"
                          styleClass="vgt-table condensed !border-0 !ring-0 !text-xs !font-sans "
                        >
                          <div
                            slot="emptystate"
                            class="text-center my-2 blur-sm"
                          >
                            <Icon
                              icon="mdi:loading"
                              class="
                                text-4xl  text-[#FFA140]
                                animate-spin
                                mx-auto
                              "
                            />
                          </div>
                          <div slot="time">this is time</div>
                        </vue-good-table>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="mt-6 rounded-md bg-slate-200 w-full"
                  style="height: 40px"
                >
                  <button @click="redirectToComingsoon" class="mt-2 w-full">
                    <h1 class="antialiased text-[#104050] text-center font-bold">
                      View More Blocks
                    </h1>
                  </button>
                </div>

                <div class="grid grid-cols-2 pt-2 rounded-md px-72"></div>
              </div>
            </div>

            <div>
              <div class="grow ml-2 p-6 bg-white rounded-md">
                <div class="flex flex-col">
                  <div class="flex flex-row">
                    <div class="bg-white">
                      <span
                        class="text-2xl antialiased text-justify text-[#104050]"
                      >
                        <Icon icon="clarity:blocks-group-solid" />
                      </span>
                    </div>
                    &nbsp; &nbsp;
                    <div>
                      <span
                        class="text-2xl antialiased text-justify text-[#104050]"
                      >
                        Recent Transactions
                      </span>
                    </div>
                  </div>
                  <hr class="my-2"/>
                </div>

                <div class="rounded-md flex flex-col w-full">
                  <span>
                    <div>
                      <div
                        class="flex flex-row h-screen w-full"
                        style="height: 500px"
                      >
                        <div class="rounded-md flex flex-col w-full">
                          <div class="h-full flex flex-row max-h-screen">
                            <div class="rounded-sm w-full scrollbar">
                              <vue-good-table
                                :columns="transactionColumns"
                                :rows="transactionBlocks"
                                :sort-options="{
                                  enabled: false,
                                  multipleColumns: false,
                                  initialSortBy: [
                                    { field: 'txblock', type: 'desc' },
                                  ],
                                }"
                                styleClass="vgt-table condensed !border-0 !ring-0 !text-xs !font-sans"
                              >
                                <div
                                  slot="emptystate"
                                  class="text-center my-2 blur-sm"
                                >
                                  <Icon
                                    icon="mdi:loading"
                                    class="
                                      text-4xl text-[#FFA140]
                                      animate-spin
                                      mx-auto
                                    "
                                  />
                                </div>
                              </vue-good-table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="mt-6 rounded-md bg-slate-200 columns-1 w-full"
                        style="height:40px"
                      >
                        <button @click="redirectToTx" class="mt-2 w-full">
                          <h1 class="antialiased text-[#104050] text-center font-bold">
                            View More Transactions
                          </h1>
                        </button>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="py-10"></div>
      </div>
      <div class="flex flex-col">
        <FooterView />
      </div>
    </div>

  </div>
</template>

<script>
import FooterView from "../components/FooterView.vue";
import VueApexCharts from "vue-apexcharts";
import { Icon } from "@iconify/vue2";
import { ethers } from "ethers";
import Tx from "../views/Tx.vue";
import Block from "../views/Block.vue";

export default {
  name: "HomeView",
  components: {
    FooterView,
    Icon,
    apexchart: VueApexCharts,
    Tx,
    Block,
  },
  data() {
    return {
      columns: [
        {
          // label: 'Blocks',
          field: this.BlocksField,
          html: true,
        },
        {
          field: "blocks",
          html: true,
        },
        {
          // label: 'Miner',
          field: this.MinerField,
          html: true,
        },
        {
          // label: 'Tx Count',
          field: this.TxCount,
          html: true,
        },
      ],
      transactionColumns: [
        {
          // label: 'Blocks',
          field: this.BlocksField,
          html: true,
        },
        {
          field: "txblock",
          html: true,
        },
        // {
        // 	field: 'time',
        // html: true
        // },
        {
          // label: 'Transactions',
          field: this.Transactions,
          html: true,
        },
        {
          // label: 'TxAmount',
          field: this.TxAmount,
          html: true,
        },
      ],
      currentDiff: "",
      blocks: "",
      dataStore: {},
      gLimit: "",
      miner: "",
      currentBlocks: [],
      currentBlock: {},
      transactionBlocks: [],
      transactionBlock: {},
      time: "",
      query: "",

      chartOptions: {
        chart: {
          id: "chart",
          height: 410,
          type: "bar",
          toolbar: { show: false },
          animations: { enabled: true },
        },
        fill: {
          colors: ["#FFA140"],
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 2.5,
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",

          labels: {
            show: false,
          },
        },

        yaxis: {
          categories: [],
          position: "left",

          labels: {
            show: true,
          },
        },
      },

      series: [
        {
          name: "Number of transactions",
          data: [],
        },
      ],
    };
  },
  methods: {
    redirectToComingsoon() {
      this.$router.push('/Block')
    },
    redirectToTx() {
      this.$router.push('/Tx')
    },
    routeTo(dest){
  //  router.push({ path:'/Comingsoon.vue'})
     window.open(dest,'Comingsoon')
    },

    // Traction functions
    Transactions(row) {
      let m = `From: ${row.from} <br/> To: ${row.to}`;
      return m;
    },
    BlocksField(row) {
      let temp =
        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36" class="mr-3 text-[#104050] iconify iconify--clarity"><path fill="currentColor" d="m31.42 9.09l-13-6a1 1 0 0 0-.84 0l-13 6A1 1 0 0 0 4 10v17a1 1 0 0 0 .58.91l13 6a1 1 0 0 0 .84 0l13-6A1 1 0 0 0 32 27V10a1 1 0 0 0-.58-.91ZM18 14.9L7.39 10L18 5.1L28.61 10Zm12 11.46l-11 5.08v-14.8l11-5.08Z" class="clr-i-solid clr-i-solid-path-1"></path><path fill="none" d="M0 0h36v36H0z"></path></svg>';
      return temp;
    },
    TxAmount(row) {
      let temp = `Amount : ${row.txAmount} <br /> Time : ${row.time} sec ago`;
      return temp;
    },
    // Recent Blocks functions
    MinerField(row) {
      let m = `Miner: ${row.miner} <br /> `;
      return m;
    },
    TxCount(row) {
      let temp = `Tx : ${row.txcount} <br /> Time : ${row.time} sec ago`;
      return temp;
    },
    TransactionMinerField(row) {
      return row;
    },
    hexToDec(hex) {
      return parseInt(hex, 16);
    },

    async getdata(){
      let provider = await new ethers.providers.JsonRpcProvider({url: "https://rpc.all.tz",}); 
          let x = 42
          let y = 66 
          let paramLength = this.query.length
      
      if (paramLength == x) { // Account Address hash
        this.$router.push({
          name: "Accountdetails",
          params: {accountAddress: this.query}
        })
      } 
      else if ( paramLength == y) { // Transaction Hash
        this.$router.push({
          name: "Transactiondetails",
          params: {transactionAddress: this.query}
        })
      } 
      else {
        this.$router.push({
          name: "Blockdetails",
          params: {blockAddress: this.query}
        })
      } 
    },

  },
  
  watch: {
    // whenever question changes, this function will run
    blocks(newBlocks, oldBlocks) {
      return newBlocks;
    },
    time(newTime, oldTime) {
      return newTime - oldTime;
    },
  },
  async mounted() {
    let first = document.querySelectorAll(".vgt-table tr")[0];
    let second = document.querySelectorAll(".vgt-table tr")[2];
    first.style.display = "none";
    second.style.display = "none";
    
    this.$store.commit("setLoading", true)
    setInterval(async () => {
      let provider = new ethers.providers.JsonRpcProvider({
        url: "https://rpc.all.tz",
      });
      this.tx = await provider.getBlockWithTransactions();
      this.miner = await provider.getBlock();
      this.blocks = await provider.getBlockNumber();
      let timestamp = this.miner.timestamp;
      let time = new Date(this.miner.timestamp).toLocaleTimeString([], {
        second: "2-digit",
      });
      // let data = await provider.getFeeData();
      // console.log("this data",data);
      
      // console.log("block",this.miner)
      if(this.gasprice == undefined){
        this.$store.commit("setLoading", true)
        // console.log("show loading", this.gasprice );
      }else{
        this.$store.commit("setLoading", false)
        // console.log("NO loading", this.gasprice );
      }
      this.transactionBlock = {};
      this.transactionBlock.txblock = this.tx.number;
      this.transactionBlock.time = time;
      this.transactionBlock.txAmount =
        this.hexToDec(this.tx.transactions[0]?.value?._hex) / 1e18;
      this.transactionBlock.from = this.tx.transactions[0]?.from;
      this.transactionBlock.to = this.tx.transactions[0]?.to;
      let txIdx = this.transactionBlocks.findIndex(
        (item) => item.txblock == this.tx.number
      );

      if (txIdx == -1 && this.transactionBlock.from != undefined) {
        this.transactionBlocks.push(this.transactionBlock);
      }
      // console.log(this.transactionBlocks);

      //CURRENT BLOCKS LOADING AND FORMATTING
      this.currentBlock = {};
      this.currentBlock.time = time;
      this.currentBlock.blocks = this.blocks;
      this.currentBlock.miner = this.miner.miner.toLowerCase();
      this.currentBlock.txcount = this.tx.transactions.length;

      let idx = this.currentBlocks.findIndex(
        (item) => item.blocks == this.blocks
      );

      if (idx == -1) {
        this.currentBlocks.push(this.currentBlock);
      }

      // END CURRENT BLOCK
      let cBlock = await provider.getBlock(this.blocks);
      this.gasprice = await provider.getGasPrice();
      

      this.gasprice = ethers.utils.formatEther(this.gasprice);
      
      if (this.gasprice != undefined) {
        document.getElementById("gasprice").innerHTML = this.gasprice;
      }

      let difficulty = (cBlock.difficulty / 1000000).toFixed(6).toString().split("");
      difficulty.pop();
      difficulty.pop();
      difficulty.pop();

      let difficult2 = "";
      difficulty.forEach((e) => {
        difficult2 += e;
      });

      difficult2 = difficult2 + "M";
      
      document.getElementById("currentDiffSpan").innerHTML = difficult2;

      this.chartOptions.xaxis.categories.push(this.transactionBlock.time);
      this.series[0].data.push(this.currentBlock.txcount);
      // console.log("this series", this.series[0].data);

      window.dispatchEvent(new Event("resize"));

      // console.log("this time", this.transactionBlock.time);
      // console.log("this txcount", this.currentBlock.txcount);
  
    }, 5000);

    // console.log("Interval", this.time);
    //console.log("this series",this.series[0].data);
  },
};
</script>
<style lang="scss">
     #customtext2 {
     transform: perspective(1500px) rotateY(2deg);
     transition: transform 1s ease 0s;
        @keyframes blurEffect {
     0% {
       filter: blur(0);
     }
     50% {
       filter: blur(10px);
     }
     100% {
       filter: blur(0);
     }
   }
   
   
     &:hover {
      animation: blurEffect 0.2s;
       transform: perspective(3000px)  scale(1.1);
     }

     }
     @keyframes pulseAnimation {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 110% 110%;
  }
  100% {
    background-size: 100% 100%;
  }
}

#blockcount {
  background: rgb(255,161,64);
  background: radial-gradient(circle, rgba(255,161,64,1) 50%, rgba(16,64,80,1) 100%);
  animation: pulseAnimation 5s ease infinite;
}

@keyframes pulseAnimation2 {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 110% 110%;
  }
  100% {
    background-size: 100% 100%;
  }
}
</style>