<template>
  <v-overlay class="backdrop-blur-sm z-50">
    <Icon
      icon="mdi:loading"
      class="text-8xl text-[#FFA140] animate-spin m-auto"
    />
  </v-overlay>
</template>
<script>
import { Icon } from "@iconify/vue2";
export default {
  name: "LoaderCustom",
  components: {
    Icon,
  }
};
</script>

<style>
</style>