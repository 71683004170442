<template>
  <div style="background-color: #104050" class="pt-10">
    <div class="hidden lg:block md:block">
      <div class="md:px-10 lg:px-10 text-white">
        <h1 class="text-3xl font-bold">Alphachain</h1>
      </div>
      <div
        class="
          flex flex-row
          justify-center
          lg:flex-row
          sm:flex-row
          md:flex-row
          text-white
        "
      >
        <div class="text-left pb-4 md:px-10 lg:px-10 lg:w-1/3 md:w-1/3">
          <div class="py-5 flex flex-column">
            <span>Follow us</span>
            <div class="pt-2">
              <v-icon
                class="justify-start mr-2"
                color="white"
                x-large
                @click="window.open('https://facebook.com')"
                >mdi-facebook</v-icon
              >
              <v-icon
                class="justify-start mr-2"
                color="white"
                x-large
                @click="window.open('https://facebook.com')"
                >mdi-twitter</v-icon
              >
              <v-icon
                class="justify-start mr-2"
                color="white"
                x-large
                @click="window.open('https://facebook.com')"
                >mdi-linkedin</v-icon
              >
              <v-icon
                class="justify-start mr-2"
                color="white"
                x-large
                @click="window.open('https://facebook.com')"
                >mdi-instagram</v-icon
              >
            </div>
          </div>
        </div>

        <div
          class="
            text-left
            pb-4
            md:px-10
            lg:px-10 lg:w-1/3
            md:w-1/3
            flex-1 flex-column flex-wrap
          "
        >
      
          <div class="flex-1 flex-row mt-2 hover:cursor-pointer">
            <v-icon color="white"> mdi-chevron-right </v-icon>
            <a style="color: white;" href="https://alphachain.all.co.tz/" >Home</a>
          </div>
        <!-- <div class="flex-1 flex-row mt-2">
            <v-icon color="white"> mdi-chevron-right </v-icon>
            <a style="color: white;" href="https://explorer.all.co.tz">Explorer V1</a>
          </div> -->
          <div class="flex-1 flex-row mt-2">
            <v-icon color="white"> mdi-chevron-right </v-icon>
              <a style="color: white;" href="https://faucet.all.co.tz">Faucet</a>
          </div>
          
          <div class="flex-1 flex-row mt-2"  v-if="activeTab == 'dapps'">
            <v-icon color="#FFA140"> mdi-chevron-right </v-icon>
            <router-link to="/dapps" class="font-bold hover:cursor-pointer" style="color: #FFA140;">dApps</router-link>
          </div>
          <div class="flex-1 flex-row mt-2"  v-else>
            <v-icon color="white"> mdi-chevron-right </v-icon>
            <router-link to="/dapps" class="hover:cursor-pointer" style="color: white;">dApps</router-link>
          </div>

          <div class="flex-1 flex-row mt-2"  v-if="activeTab == 'wallet'">
            <v-icon color="#FFA140"> mdi-chevron-right </v-icon>
            <router-link to="/Wallet" class="font-bold hover:cursor-pointer" style="color: #FFA140;">Wallet</router-link>
          </div>
          <div class="flex-1 flex-row mt-2"  v-else>
            <v-icon color="white"> mdi-chevron-right </v-icon>
            <router-link to="/Wallet" class="hover:cursor-pointer" style="color: white;">Wallet</router-link>
          </div>
        </div>

        <div class="text-left pb-4 md:px-10 lg:px-10 lg:w-1/3 md:w-1/3">
          <h1 class="text-2xl font-bold">Join our community</h1>
          <div
          class="flex">
            <button
            class="
              bg-amber-400
              px-5
              py-2
              rounded-md
              drop-shadow-md
              text-black
              mt-3
            "
            @click="routeTo('https://oxygen.co.tz/#/room/#dev:app.oxygen.co.tz')"
          >
            Join Us 
          </button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button 
            @click="addNetwork1()"
              class="
              bg-amber-400
              px-5
              py-2
              rounded-md
              drop-shadow-md
              text-black
              mt-3
            "
            >
            <div class="flex flex-row">
              <img src="@/assets/icon-128.png" width="20px">
             <span class="ml-2">Add Alphachain network</span>
            </div>
            </button>
          </div>
        </div>
      </div>
      <div class="text-[#D8D8D8] text-center  pb-4 italic">
        Developed and maintained by eGA, Copyright © 2014 - 2024 e-Government Authority
       </div>
    </div>

    <div class="lg:hidden md:hidden">
      <div class="px-5 !text-white">
        <h1 class="text-3xl font-bold">Alphachain</h1>
      </div>
      <div
        class="
          flex flex-column
          justify-center
          text-white
        "
      >
        <div class="text-left pb-4 px-5 lg:w-1/3 md:w-1/3">
          <div class="py-5 flex flex-column">
            <span>Follow us</span>
            <div class="pt-2">
              <v-icon
                class="justify-start mr-2"
                color="white"
                x-large
                @click="window.open('https://facebook.com')"
                >mdi-facebook</v-icon
              >
              <v-icon
                class="justify-start mr-2"
                color="white"
                x-large
                @click="window.open('https://facebook.com')"
                >mdi-twitter</v-icon
              >
              <v-icon
                class="justify-start mr-2"
                color="white"
                x-large
                @click="window.open('https://facebook.com')"
                >mdi-linkedin</v-icon
              >
              <v-icon
                class="justify-start mr-2"
                color="white"
                x-large
                @click="window.open('https://facebook.com')"
                >mdi-instagram</v-icon
              >
            </div>
          </div>
        </div>

        <div
          class="
            text-left
            pb-4
            px-5
            md:px-10
            lg:px-10 lg:w-1/3
            md:w-1/3
            flex-1 flex-column flex-wrap
          "
        >
        
        <div class="flex-1 flex-row mt-2">
            <v-icon color="white"> mdi-chevron-right </v-icon>
            <a style="color: white;" href="https://alphachain.all.co.tz/" class="hover:cursor-pointer">Home</a>
          </div>
        <div class="flex-1 flex-row mt-2">
            <v-icon color="white"> mdi-chevron-right </v-icon>
            <a style="color: white;"  href="https://explorer.all.co.tz">Explorer V1</a>
          </div>
          <div class="flex-1 flex-row mt-2">
            <v-icon color="white"> mdi-chevron-right </v-icon>
             <a style="color: white;" href="https://faucet.all.co.tz">Faucet</a>
          </div>
          <div class="flex-1 flex-row mt-2"  v-if="activeTab == 'dapps'">
            <v-icon color="#FFA140"> mdi-chevron-right </v-icon>
            <router-link to="/dapps" class="font-bold hover:cursor-pointer" style="color: #FFA140;">dApps</router-link>
          </div>
          <div class="flex-1 flex-row mt-2"  v-else>
            <v-icon color="white"> mdi-chevron-right </v-icon>
            <router-link to="/dapps" class="hover:cursor-pointer" style="color: white;">dApps</router-link>
          </div>

          <div class="flex-1 flex-row mt-2"  v-if="activeTab == 'wallet'">
            <v-icon color="#FFA140"> mdi-chevron-right </v-icon>
            <router-link to="/Wallet" class="font-bold hover:cursor-pointer" style="color: #FFA140;">Wallet</router-link>
          </div>
          <div class="flex-1 flex-row mt-2"  v-else>
            <v-icon color="white"> mdi-chevron-right </v-icon>
            <router-link to="/Wallet" class="hover:cursor-pointer" style="color: white;">Wallet</router-link>
          </div>
          
        </div>

        <div class="text-left pb-4 px-5">
          <h1 class="text-2xl font-bold">Join our community</h1>
          <button
            class="
              bg-amber-400
              px-5
              py-2
              rounded-md
              drop-shadow-md
              text-black
              mt-3
            "
            @click="routeTo('https://oxygen.co.tz/#/room/#dev:app.oxygen.co.tz')"
          >
            Join Us
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
          <button 
            @click="addNetwork1()"
              class="
              bg-amber-400
              px-5
              py-2
              rounded-md
              drop-shadow-md
              text-black
              mt-3
            "
            >
            <div class="flex flex-row">
              <img src="@/assets/icon-128.png" width="20px">
             <span class="ml-2">Add Alphachain network</span>
            </div>
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { dataStore } from '../states/states.ts'
import { dataStore } from '../states/states.js'
import { addNetwork } from './walletConnection'

export default {
  name: "FooterView",
  data() {
    return dataStore
  },
  methods: {
    routeTo(dest) {
      window.open(dest,'_blank');
    },

    // async addNetwork(type: string) {



    //         // if (type === 'web3') {
    //         //     if (typeof ethereum !== 'undefined') {
    //         //         eth = new Web3Eth(ethereum);
    //         //     } else if (typeof web3 !== 'undefined') {
    //         //         eth = new Web3Eth(web3.givenProvider);
    //         //     } else {
    //         //         eth = new Web3Eth(ethereum);
    //         //     }
    //         // }

             

    //         // let params = [{
    //         //                 chainId: '12b3d81',
    //         //                 chainName: 'Alphachain Live',
    //         //                 nativeCurrency: {
    //         //                     name: 'ALP',
    //         //                     symbol: 'ALP',
    //         //                     decimals: 18
    //         //                 },
    //         //                 rpcUrls: ['https://rpc.all.tz'],
    //         //                 blockExplorerUrls: ['https://explorer.all.co.tz']
    //         //             }]

    //         //             let w = window.web3

                
    //         //     window.ethereum.request({ method: 'wallet_addEthereumChain', params })
    //         //         .then(() => console.log('Success'))
    //         //         .catch((error: any) => console.log("Error", error.message));


    //     }
    async addNetwork1() {
      addNetwork()      
}
  }
};
</script>

<style>
</style>