<template>
    <div>
     <div class="pl-10 pt-8 flex flex-col bg-white h-24">
         <div class="flex flex-col pd-10 mx-80">
           <span class="text-2xl text-2sm  antialiased text-justify text-[#104050]">Alphachain Explorer</span>
         </div>
       </div>
       <div style="background-color: #104050; height: 170px" class="p-6 pl-10 flex flex-col">
           <div class="flex flex-col mx-80 ">
            <form @submit.prevent="getdata">   
                  <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search</label>
                  <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                          <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                      </div>
                      <input type="search" v-model="query" class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tx hash, Address or Blocks" required>
                      <button  type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-[#104050] hover:bg-[#FFA140] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-[#104050] dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                  </div>
            </form>
           </div>

           <div class="flex flex-col-2">
             
             <div class="flex flex-row mx-80">
               <span class="text-2xl text-2sm  antialiased text-justify py-8 text-white">
                Transaction Details
             </span>
             </div>
             
             <div class="flex flex-row ml-96 py-8">
               <button @click="redirectToHome" class="antialiased text-justify py-2 text-white  px-2 bg-[#104050] hover:bg-[#FFA140] rounded-lg drop-shadow-md">
                 Home
               </button>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button  @click="routeTo('https://faucet.all.co.tz/')"  class="antialiased text-justify py-2 text-white  px-2 bg-[#104050] hover:bg-[#FFA140] rounded-lg drop-shadow-md">
                 Faucet
               </button>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <button  @click="routeTo('https://alphachain.all.co.tz/dapps')"   class="antialiased text-justify py-2 text-white px-2 bg-[#104050] hover:bg-[#FFA140] rounded-lg drop-shadow-md">
                 dApps
               </button>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               <button  @click="routeTo('https://alphachain.all.co.tz/Wallet')" class="antialiased text-justify py-2 text-white  px-2 bg-[#104050] hover:bg-[#FFA140] rounded-lg drop-shadow-md">
                 Wallet
               </button> 
              </div>
           </div>
         </div>
       <div class="bg-[#E2E8F0] flex flex-col" style="height:1000px">

         <div class="bg-white rounded-lg mt-6 mx-80" style="height:950px">
           
           <div class="flex flex-col">
             
             <div class="rounded-sm m-10 h-full">
                <div class="flex flex-row rounded-lg" style="height:770px">             
                  
                    <div class="flex flex-row w-1/4 ">
                        
                      <div class="flex flex-col">
                      <span class="text-[#104050] font-bold p-8">
                        Transaction Hash:
                      </span> 

                      <span class="text-[#104050] font-bold p-8">
                        Status: 
                      </span> 
                      <span class="text-[#104050] font-bold p-8">
                        Timestamp: 
                      </span> 
                      <span class="text-[#104050] font-bold p-8">
                        Block:  
                      </span> 
                      <span class="text-[#104050] font-bold p-8">
                        From: 
                      </span> 
                      <span class="text-[#104050] font-bold p-8">
                        To: 
                      </span> 
                      <span class="text-[#104050] font-bold p-8">
                        Value:
                      </span> 
                      <span class="text-[#104050] font-bold p-8">
                        Gas price:
                      </span> 
                      <span class="text-[#104050] font-bold p-8">
                        Gas Limit:
                      </span> 

                      </div>

                    </div>
                    <div class="flex flex-row w-3/4">
                      
                      <div class="flex flex-col p-8">
                      <span class="text-[#104050] font-bold">
                      {{transactionAddress}}
                      </span>

                      <span class="text-[#104050] font-bold py-16 ">
                        Confrimation
                      </span>
                      <span class="text-[#104050] font-bold flex flex-row">
                        <Icon icon="fluent-mdl2:buffer-time-after" />
                        59 mins ago (Oct-17-2022 12:12:23 PM +UTC) 
                      </span>
                      <span class="text-[#104050] font-bold  py-16 ">
                        15767751                      
                      </span>
                      <span class="text-[#104050] font-bold ">
                        0x30a5066b2c6EFa86E48Bb304172debbDB83fe01c
                      </span>
                      <span class="text-[#104050] font-bold py-16">
                        0x30a5066b2c6EFa86E48Bb304172debbDB83fe01c
                      </span> 
                      <span class="text-[#104050] font-bold">
                        58,750 Alp
                      </span> 
                      <span class="text-[#104050] font-bold py-16">
                        0.000000001
                      </span>
                      <span class="text-[#104050] font-bold">
                        8,000,000 m/s
                      </span>        
                    </div>

                      
                  </div>
                  
                  </div>
                   <div> 
                   

               </div>
             </div>
           

             <!-- <div class="flex pl-8 rounded-lg justify-between">
             <div class="flex justify-start">
               
               <button class="antialiased text-justify px-5 py-2 text-white bg-[#104050] hover:bg-[#FFA140] rounded-lg drop-shadow-md">
                 Back
               </button>
                </div>
               <div class="flex justify-end pr-8">
                 
               <button class="antialiased text-justify px-5 py-2 text-white bg-[#104050] hover:bg-[#FFA140] rounded-lg drop-shadow-md">
                 Next
               </button>
             </div>  
             </div> -->

           </div>
         </div>

       </div>
     <div>
     <FooterView/>
     </div>

   </div>
</template>

<script>
import FooterView from "../components/FooterView.vue";
import { VueGoodTable } from 'vue-good-table';
import { ethers } from "ethers";
import { Icon } from "@iconify/vue2";


export default {
    name:"Transactiondetails",

    components:{
        FooterView,
        VueGoodTable,
        Icon,
        
    },
    data(){
    return {
      query: null,
      data: [],
      columns: [
       
      ],
      rows: [
        
      ],
      currentBlocks: [],
      transactionBlocks: [],
      transactionAddress: "",

      }
  },
  methods: {
   //home
   redirectToHome() {
      this.$router.push('/')
    },
    //Blank
    routeTo(dest) {
      window.open(dest,'_blank');
    },
    async getdata(){
      let provider = await new ethers.providers.JsonRpcProvider({url: "https://rpc.all.tz",}); 
          let x = 42
          let y = 66 
          let paramLength = this.query.length
      
      if (paramLength == x) { // Account Address hash
        this.$router.push({
          name: "Accountdetails",
          params: {accountAddress: this.query}
        })
      } 
      else if ( paramLength == y) { // Transaction Hash
        this.$router.push({
          name: "Transactiondetails",
          params: {transactionAddress: this.query}
        })
      } 
      else {
        this.$router.push({
          name: "Blockdetails",
          params: {blockAddress: this.query}
        })
      } 
    },

  },
  watch: {
    // whenever question changes, this function will run
    blocks(newBlocks, oldBlocks) {
      return newBlocks;
    },
    time(newTime, oldTime) {
      return newTime - oldTime;
    },
  },
  
  async mounted() {
   
    this.transactionAddress = this.$route.params.transactionAddress

    setInterval(async () => {
      let provider = new ethers.providers.JsonRpcProvider({
        url: "https://rpc.all.tz",
      });
      this.tx = await provider.getBlockWithTransactions();
      this.miner = await provider.getBlock();
      this.blocks = await provider.getBlockNumber();
      let time = new Date(this.miner.timestamp).toLocaleTimeString([], {
        second: "2-digit",
      });
      // let data = await provider.getFeeData();
      // console.log("this data",data);
      
      // console.log("block",this.miner)

      this.transactionBlock = {};
      this.transactionBlock.txblock = this.tx.number;
      this.transactionBlock.time = time;
      this.transactionBlock.txAmount =
        this.hexToDec(this.tx.transactions[0]?.value?._hex) / 1e18;
      this.transactionBlock.from = this.tx.transactions[0]?.from;
      this.transactionBlock.to = this.tx.transactions[0]?.to;
      let txIdx = this.transactionBlocks.findIndex(
        (item) => item.txblock == this.tx.number
      );

      if (txIdx == -1 && this.transactionBlock.from != undefined) {
        this.transactionBlocks.push(this.transactionBlock);
      }

      //CURRENT BLOCKS LOADING AND FORMATTING
      this.currentBlock = {};
      this.currentBlock.time = time;
      this.currentBlock.blocks = this.blocks;
      this.currentBlock.miner = this.miner.miner.toLowerCase();
      this.currentBlock.txcount = this.tx.transactions.length;

      let idx = this.currentBlocks.findIndex(
        (item) => item.blocks == this.blocks
      );

      if (idx == -1) {
        this.currentBlocks.push(this.currentBlock);
      }

      // END CURRENT BLOCK
      let cBlock = await provider.getBlock(this.blocks);
      this.gasprice = await provider.getGasPrice();
      

      this.gasprice = ethers.utils.formatEther(this.gasprice);
      
      if (this.gasprice != undefined) { document.getElementById("gasprice").innerHTML = this.gasprice;}

      let difficulty = (cBlock.difficulty / 1000000)
        .toFixed(6)
        .toString()
        .split("");
      difficulty.pop();
      difficulty.pop();
      difficulty.pop();

      let difficult2 = "";
      difficulty.forEach((e) => {
        difficult2 += e;
      });

      difficult2 = difficult2 + "M";
      document.getElementById("currentDiffSpan").innerHTML = difficult2;

      this.chartOptions.xaxis.categories.push(this.transactionBlock.time);
      this.series[0].data.push(this.currentBlock.txcount);
      // console.log("this series", this.series[0].data);

      window.dispatchEvent(new Event("resize"));
    }, 5000);
  },

};
</script>