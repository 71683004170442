import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/tailwind.css'
import VueApexCharts from 'vue-apexcharts'
import VueGoodTablePlugin from 'vue-good-table' 
import('vue-good-table/dist/vue-good-table.css')

Vue.config.productionTip = false;
Vue.component('apexchart', VueApexCharts)

Vue.use(VueGoodTablePlugin)
Vue.filter("truncate", function(value){
  if(value){
    return value.substring(0, 20) + "..."
  }else {
    return value;
  }
})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
