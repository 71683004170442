<template>
  <div>
     <div class="hidden lg:block md:hidden sm:shrink-0 bg-slate-200">
      <div class="flex flex-column mx-80">
      <div class="flex flex-column pb-96 py-8">
        <v-card elevation="12" class="flex flex-column rounded pb-10">
          <span class="text-9xl font-bold px-60 p-36" style="color: #ffa140">
          Coming Soon
        </span>
          <center><div class="flex flex-col w-56 drop-shadow-md">
            <button class="bg-[#FFA140] hover:bg-[#FBBF24] text-2xl p-4 antialiased px-20 rounded-md drop-shadow-md text-white mt-3" @click="returnbackhome">
           Back
          </button>
          </div>
        </center>
        </v-card>
      </div>
    </div>    
    <div class="mt-24 pt-1">
      <FooterView />
    </div>

    </div>

    <div class="hidden md:block lg:hidden sm:hidden bg-slate-200">
      <div class="flex flex-col ">
        <div class="flex flex-col pb-96 h-1/2">
          <span class="flex flex-col text-9xl font-bold h-36 px-60 mt-96" style="color: #ffa140">        
                Coming Soon
          </span>
        </div>
      </div>
      <div class="">
        <FooterView />
      </div>
    </div> 

    <div class="sm:block lg:hidden md:hidden xl:hidden 2xl:hidden  bg-slate-200">
      <div class="flex flex-row">
        <div class="flex flex-row pb-96 h-1/2">
          <span class="text-8xl font-bold mt-8" style="color: #ffa140">          
            Coming Soon
          </span>
        </div>
      </div>
      <div class="">
        <FooterView />
      </div>
    </div>
    
  </div>
</template>
  
  <script>
import FooterView from "../components/FooterView.vue";

export default {
  name: "Comingsoon",
  components: {
    FooterView,
  },
  methods: {
    returnbackhome() {
      this.$router.push('/')
    },
  },
};
</script>
  
  <style>
</style>